<template>
<div>
    <div class="account" v-show="loggedin">
        <h4>Hi {{ this.data['username'] }}</h4>
        <h5>Welcome. Below are the actions you are allowed to perform</h5>
        <h6 v-show="admin">Add new users and update existing have access to this webpage.</h6>
        <v-btn depressed v-show="admin" href="/users" style="color:#263AA2">Users</v-btn>
        <h6 v-show="devices">Your devices are Realme 5 Pro(RMX1971) and Realme 5 Series(r5x). So you are allowed to perform actions only on those two devices.</h6>
        <h6 v-show="rmx1971">Your device is Realme 5 Pro(RMX1971). So you are allowed to perform actions only on that device.</h6>
        <h6 v-show="r5x">Your device is Realme 5 Series(r5x). So you are allowed to perform actions only on that device.</h6>
        <v-btn depressed v-show="builds" href="/builds" style="color:#263AA2">Builds</v-btn>
        <h6 v-show="maintainers">Add new maintainers and update existing maintainers that contribute to KharaMe devices.</h6>
        <v-btn depressed v-show="maintainers" href="/maintainers" style="color:#263AA2">Maintainers</v-btn>
        <h6 v-show="blogs">Add  new blog posts and update existing blog posts. Blog Posts include guides and reviews as well.</h6>
        <v-btn depressed v-show="blogs" href="/blog" style="color:#263AA2">Blog Posts</v-btn>
        <h6>Work Done? What are you waiting for then Logout.</h6>
        <v-btn depressed style="color:#263AA2" @click="logout()" href="/">Logout</v-btn>
    </div>
  <div class="notfound" v-show="notloggedin">
    <img src="../assets/404.png" alt="404(Not Found)">
    <h1>404</h1>
    <p>The page you are looking for is not available</p>
  </div>
</div>
</template>

<script>
  export default {
    name: 'Account',
    components: {

    },
    data: () => ({
      notloggedin: true,
      current_time: '',
      loggedin: false,
      username: '',
      data: '',
      devices: false,
      rmx1971: false,
      r5x: false,
      admin: false,
      builds: false,
      blogs: false,
      maintainers: false,
    }),

    mounted() {
      this.checkloggedin()
    },

    methods: {
      logout() {
        sessionStorage.clear();
      },
      checkloggedin() {
        this.current_time = Math.round(+new Date()/1000)
        this.data = JSON.parse(sessionStorage.getItem("data"))
        if (this.data && sessionStorage.getItem("expire_time") > this.current_time) {
          this.loggedin = true,
          this.notloggedin = false
        }
        if (this.data['rmx1971'] === 'Y' && this.data['r5x'] === 'Y') {
          this.devices = true
        } else if (this.data['r5x'] === 'Y') {
          this.r5x = true
        } else {
          this.rmx1971 = true
        }
        if (this.data['admin'] === 'Y') {
          this.admin = true
        }
        if (this.data['builds'] === 'Y') {
          this.builds = true
        }
        if (this.data['blog'] === 'Y') {
          this.blogs = true
        }
        if (this.data['maintainers'] === 'Y') {
          this.maintainers = true
        }
      }
    },
  }
</script>
<style scoped>
.account {
    color: white;
    margin-top: 1%;
    margin-left: 15%;
    margin-right: 15%;
    padding: 2%;
}
.account h6 {
  margin-top: 20px;
}
.notfound {
	text-align: center;
	margin-top: 7%;
  color: white;
}
</style>